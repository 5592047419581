import { KKDataPregnancyEnum, KKDataPreparePatientEnum } from 'ique-api';

export const displayNameForPregnancy = (p: KKDataPregnancyEnum): string => {
  switch (p) {
    case 'Normal_Pregnancy': {
      return 'Normal graviditet';
    }
    case 'Pregnancy_Induced_Hypertension': {
      return 'Graviditetshypertoni';
    }
    case 'Preeclampsia': {
      return 'Preeklampsi';
    }
    case 'Severe_Preeclampsia': {
      return 'Svår preeklampsi';
    }
    case 'Other': {
      return 'Övrigt';
    }
    default: {
      return '';
    }
  }
};

export const displayNameForPreparePatient = (type: KKDataPreparePatientEnum | undefined): string => {
  switch (type) {
    case 'LayingDown': {
      return 'liggande';
    }
    case 'Sitting': {
      return 'sittande';
    }
    default: {
      return '';
    }
  }
};

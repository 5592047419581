import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Checkbox, Grid, TextInput } from '@mantine/core';
import { QueueItem } from 'ique-api';
import { useAddQueueItem } from '../../../../api/addQueueItem';
import { useUpdateQueueItem } from '../../../../api/updateQueueItem';

type QueueItemFormProps = {
  queueId?: string;
  itemData?: QueueItem;
  onSubmitted?: () => void;
};

type Inputs = QueueItem;

const getDefaultValues = (itemData?: QueueItem) => ({
  ...itemData,
  priority: itemData?.priority
});

const QueueItemForm = ({ queueId, itemData, onSubmitted }: QueueItemFormProps) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: getDefaultValues(itemData)
  });

  const [isCallBeforeEnabled] = watch(['callBefore']);

  const addItem = useAddQueueItem();
  const updateItem = useUpdateQueueItem();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (itemData) {
      updateItem.mutate({
        ...itemData,
        ...data
      } as QueueItem);
    } else {
      addItem.mutate({
        ...data,
        id: '',
        createdBy: '',
        rank: '',
        queueId: queueId,
        displayName: ``
      } as QueueItem);
    }
    onSubmitted && onSubmitted();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Col span={12}>
          <Checkbox label="Mottagaren ska kontakta mig" {...register('callBefore')} />
        </Grid.Col>
        {isCallBeforeEnabled && (
          <Grid.Col span={12}>
            <TextInput
              label="Telefonnummer"
              error={errors.phoneNumber && 'Ange telefonnummer'}
              required={isCallBeforeEnabled}
              {...register('phoneNumber', { required: isCallBeforeEnabled })}
            />
          </Grid.Col>
        )}

        <Grid.Col>
          <Button type="submit" fullWidth>
            {itemData ? 'Uppdatera beställning' : 'Ställ i kö'}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default QueueItemForm;

import { useMutation, useQueryClient } from 'react-query';
import { QueueItem } from 'ique-api';
import { iqueApi } from './apiConfig';

const addQueueItem = (item: QueueItem) => {
  return iqueApi.createItem(item.queueId, item);
};

export const useAddQueueItem = () => {
  const queryClient = useQueryClient();

  return useMutation(addQueueItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(['queues/item']); // TODO: Add list ID here
    }
  });
};

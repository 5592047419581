import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Group,
  Modal,
  Overlay,
  Paper,
  Switch,
  Text,
  Title,
  useMantineTheme
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { MdAdd } from 'react-icons/md';
import { Queue, QueueItem } from 'ique-api';
import QueueItemBox from '../QueueItemBox';
import Fab from '../../../../components/Fab';
import { useInProgressQueueItemList, useQueueItemList } from '../../../../api/getQueueItemList';
import { useQueueInfo } from '../../../../api/getQueueInfo';
import { useUpdateRankOfQueueItem } from '../../../../api/updateRankOfQueueItem';
import { useUpdateQueue } from '../../../../api/updateQueue';
import QueueItemForm from '../QueueItemForm';
import QueueItemDefaultForm from '../QueueItemDefaultForm';
import RequireRoleOrIsOwner from '../../../../components/RequireRoleOrIsOwner';
import { useAuth } from '../../../../lib/auth';

type QueueListProps = {
  onItemSelectionChange: (queueItem: QueueItem) => void;
  queueItemSelected?: QueueItem;
  queueId: string;
};

const QueueList = ({ onItemSelectionChange, queueItemSelected, queueId }: QueueListProps) => {
  const waitingItems = useQueueItemList({ queueId: queueId });
  const inProgressItems = useInProgressQueueItemList({ queueId: queueId });
  const queueInfo = useQueueInfo({ queueId: queueId });
  const updateRankOfQueueItem = useUpdateRankOfQueueItem();
  const updateQueue = useUpdateQueue();
  const { user } = useAuth();

  const [modalOpened, setModalOpened] = useState(false);
  const [localQueueList, setLocalQueueList] = useState<QueueItem[]>([]);
  const [localQueueInfo, setLocalQueueInfo] = useState<Queue | undefined>(undefined);
  const [isDndMode, setDndMode] = useState(false);

  useEffect(() => {
    if (JSON.stringify(waitingItems.data) !== JSON.stringify(localQueueList)) {
      setLocalQueueList(waitingItems.data || []);
    }
  }, [waitingItems.data]);

  useEffect(() => {
    if (JSON.stringify(queueInfo.data) !== JSON.stringify(localQueueInfo)) {
      setLocalQueueInfo(queueInfo.data);
    }
  }, [queueInfo.data]);

  useEffect(() => {
    setDndMode(false);
  }, [queueInfo.data?.id]);

  const { breakpoints } = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${breakpoints.xs}px)`);
  const modalSize = isMobile ? '100vw' : 'md';
  const canDragDrop: boolean = user?.globalRoles.some((r) => ['QUEUE_ADMIN'].some((a) => a === r)) || false;
  const isDragDropEnabled: boolean = canDragDrop && isDndMode;

  const handleQueueItemClick = (item: QueueItem) => {
    onItemSelectionChange(item);
  };

  const setInAlarmState = (inAlarmState: boolean) => {
    if (localQueueInfo) {
      updateQueue.mutate({
        ...localQueueInfo,
        inAlarmState: inAlarmState
      });
    }
  };

  const reorder = (list: QueueItem[] | undefined, startIndex: number, endIndex: number) => {
    const result = list ? Array.from(list) : [];

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getTimeOfAlarmStart = (alarmDurationInSeconds?: number) => {
    const alarmStart = new Date(Date.now() - 1000 * (alarmDurationInSeconds || 0));
    return alarmStart.toLocaleTimeString('sv', {
      hour: 'numeric',
      minute: 'numeric'
    });
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsChanged = reorder(localQueueList, result.source.index, result.destination.index);

    const destinationIndex = result.destination.index;

    const itemLower = itemsChanged[destinationIndex - 1];
    const item = itemsChanged[destinationIndex];
    const itemUpper = itemsChanged[destinationIndex + 1];

    const lowerRank = itemLower?.rank;
    const upperRank = itemUpper?.rank;

    setLocalQueueList(itemsChanged);
    updateRankOfQueueItem.mutate({
      queueId: queueId,
      itemId: item.id,
      lowerRank,
      upperRank,
      itemsChanged
    });
  };

  return (
    <Container mt="md">
      <Paper
        shadow="xs"
        padding="md"
        withBorder={false}
        sx={(theme) =>
          localQueueInfo?.inAlarmState
            ? {
                borderColor: theme.colors.red[6],
                borderStyle: 'solid',
                borderWidth: theme.spacing.xs / 2
              }
            : {
                borderColor: '#0000',
                borderStyle: 'solid',
                borderWidth: theme.spacing.xs / 2
              }
        }
      >
        <Group position="apart">
          <Group spacing={0} direction="column">
            <Text size="sm">{localQueueInfo?.displayName}</Text>
            <Text size="sm">
              {localQueueInfo?.ownerInfo?.firstName} {localQueueInfo?.ownerInfo?.lastName}
            </Text>
            <Text size="sm">{localQueueInfo?.ownerInfo?.username}</Text>
            <Text size="sm" color="blue">
              <a href={'tel://' + localQueueInfo?.ownerInfo?.phoneNumber}>{localQueueInfo?.ownerInfo?.phoneNumber}</a>
            </Text>
          </Group>

          <Group>
            <RequireRoleOrIsOwner roles={['QUEUE_ADMIN']} queueId={queueId}>
              {localQueueInfo?.inAlarmState ? (
                <Button onClick={() => setInAlarmState(false)}>Avsluta larm</Button>
              ) : (
                <Button color={'red'} onClick={() => setInAlarmState(true)}>
                  Starta larm
                </Button>
              )}
            </RequireRoleOrIsOwner>

            <RequireRoleOrIsOwner roles={['QUEUE_USER']} queueId={queueId}>
              {localQueueInfo?.inAlarmState && <Text color={'red'}>Larm</Text>}
            </RequireRoleOrIsOwner>
          </Group>
        </Group>
      </Paper>

      <Center m="xs">
        <Text weight="bold" color="red">
          {localQueueInfo?.inAlarmState
            ? `Kön pausad sedan ${getTimeOfAlarmStart(localQueueInfo?.alarmDurationInSeconds)}`
            : ''}
        </Text>
      </Center>

      {(inProgressItems.data || []).length > 0 && (
        <Group direction="column" grow my="sm">
          <Title order={4}>Pågående</Title>
          <Box sx={{ position: 'relative' }}>
            {localQueueInfo?.inAlarmState && (
              <Overlay opacity={0.3} color="#000" zIndex={5} style={{ pointerEvents: 'none' }} />
            )}
            {inProgressItems.data?.map((i) => (
              <QueueItemBox
                key={i.id}
                queueItem={i}
                isSelected={queueItemSelected?.id === i.id}
                onClick={() => handleQueueItemClick(i)}
              />
            ))}
          </Box>
          <Divider />
        </Group>
      )}

      <Box mb="sm" mt="lg" style={{ display: 'flex' }}>
        <Text weight="bold" color="gray" sx={{ flexGrow: '1' }}>
          {(waitingItems.data || []).length > 0 ? 'Kö' : 'Inga beställningar i kö'}
        </Text>
        {canDragDrop && (
          <Switch
            label="Prioriteringsläge"
            checked={isDndMode}
            onChange={(event) => setDndMode(event.currentTarget.checked)}
          />
        )}
      </Box>

      <Box
        sx={(theme) => ({
          position: 'relative',
          border: isDragDropEnabled ? `3px dashed ${theme.colors.gray[6]}` : undefined
        })}
      >
        {localQueueInfo?.inAlarmState && (
          <Overlay opacity={0.3} color="#000" zIndex={5} style={{ pointerEvents: 'none' }} />
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {waitingItems.data?.map((item: QueueItem, index: number) => (
                  <Draggable key={item.id} draggableId={item.rank} index={index} isDragDisabled={!isDragDropEnabled}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <QueueItemBox
                          key={item.id}
                          queuePos={index + 1}
                          queueItem={item}
                          isSelected={queueItemSelected?.id === item.id}
                          onClick={() => handleQueueItemClick(item)}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      {!modalOpened && <Fab icon={<MdAdd />} onClick={() => setModalOpened(true)} />}

      <Modal opened={modalOpened} onClose={() => setModalOpened(false)} size={modalSize} title="Ny beställning">
        {queueInfo?.data?.type === 'KK' ? (
          <QueueItemForm queueId={queueId} onSubmitted={() => setModalOpened(false)} />
        ) : (
          <QueueItemDefaultForm queueId={queueId} onSubmitted={() => setModalOpened(false)} />
        )}
      </Modal>
    </Container>
  );
};

export default QueueList;

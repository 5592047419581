import React from 'react';
import { Center, Paper, Title } from '@mantine/core';
import LoginForm from './components/LoginForm';

const Login = () => {
  // TODO get color from theme
  return (
    <div style={{ height: '100vh', backgroundColor: '#4d90cbff' }}>
      <Center>
        <Paper padding="md" mt="xl">
          <Center mb="md">
            <Title order={2}>Logga in</Title>
          </Center>
          <LoginForm/>
        </Paper>
      </Center>
    </div>
  )
};

export default Login;

import { useQuery } from 'react-query';
import { User } from 'ique-api';
import { iqueApi } from './apiConfig';

const getUsers = (): Promise<Array<User>> => {
  return iqueApi.getUsers();
};

export const useUsers = () => {
  return useQuery<Array<User>, Error>({
    queryKey: ['users'],
    queryFn: () => getUsers(),
    placeholderData: []
  });
};

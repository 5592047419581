import { createConfiguration, DefaultApi } from 'ique-api';

const config = createConfiguration({});

export const iqueApi = new DefaultApi(config)

export const fetchRejectOnHttpError = (input: RequestInfo, init?: RequestInit) =>
  fetch(input, init).then((response) => {
    if (response.ok) {
      return Promise.resolve(response);
    }
    return Promise.reject(response);
  });

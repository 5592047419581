import React, { useState } from 'react';
import { Box, Center, Grid, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { QueueItem } from 'ique-api';
import QueueList from './components/QueueList';
import ItemDetailsPanel from './components/ItemDetailsPanel';
import { useInProgressQueueItemList, useQueueItemList } from '../../api/getQueueItemList';

type QueuePageProps = {
  queueId: string;
};

const QueuePage = ({ queueId }: QueuePageProps) => {
  const waitingList = useQueueItemList({ queueId: queueId });
  const inProgressList = useInProgressQueueItemList({ queueId: queueId });
  const [queueItemSelectedId, setQueueItemSelectedId] = useState<string | undefined>(undefined);
  const { breakpoints } = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.xs}px)`);

  const allItems = [...(waitingList.data || []), ...(inProgressList.data || [])];
  const queueItemSelected: QueueItem | undefined = allItems.find((i) => i.id === queueItemSelectedId);
  const handleSelectQueueItem = (item: QueueItem | undefined) => setQueueItemSelectedId(item?.id);

  const listSpan = queueItemSelected ? (isMobile ? 0 : 6) : isMobile ? 12 : 6;
  const detailsSpan = 12 - listSpan;

  return (
    <Grid gutter={0}>
      <Grid.Col span={listSpan} sx={{ display: listSpan === 0 ? 'none' : 'visible' }}>
        <QueueList
          queueId={queueId}
          onItemSelectionChange={handleSelectQueueItem}
          queueItemSelected={queueItemSelected}
        />
      </Grid.Col>
      <Grid.Col span={detailsSpan} sx={{ display: detailsSpan === 0 ? 'none' : 'visible' }}>
        <Box
          sx={(theme) => ({
            backgroundColor: 'white',
            height: `calc(100vh - 61px)`, // Hack to not extend below bottom edge of screen and get a scrollbar
            borderLeft: `1px solid ${theme.colors.gray[2]}`
          })}
        >
          {queueItemSelected ? (
            <ItemDetailsPanel onSelectQueueItem={handleSelectQueueItem} queueItemSelected={queueItemSelected} />
          ) : (
            <Center>
              <Text mt="xl">Välj en beställning</Text>
            </Center>
          )}
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default QueuePage;

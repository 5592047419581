import { useMutation, useQueryClient } from 'react-query';
import { Queue } from 'ique-api';
import { iqueApi } from './apiConfig';

const updateQueue = (item: Queue) => {
  return iqueApi.updateQueue(item.id, item);
};

export const useUpdateQueue = () => {
  const queryClient = useQueryClient();

  return useMutation(updateQueue, {
    onSuccess: (item) => {
      queryClient.invalidateQueries(['queues/info', item.id]);
    }
  });
};

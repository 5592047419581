/**
 * Ique Api
 * Ique Api
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { User } from './User';
import { UserRoleAssociation } from './UserRoleAssociation';
import { HttpFile } from '../http/http';

export class Queue {
    'id': string;
    'displayName': string;
    'owner'?: string;
    'ownerInfo'?: User;
    'inAlarmState': boolean;
    'inAlarmStateSince'?: number;
    'alarmDurationInSeconds'?: number;
    'type': QueueTypeEnum;
    'userRoleAssociations'?: Array<UserRoleAssociation>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "displayName",
            "baseName": "displayName",
            "type": "string",
            "format": ""
        },
        {
            "name": "owner",
            "baseName": "owner",
            "type": "string",
            "format": ""
        },
        {
            "name": "ownerInfo",
            "baseName": "ownerInfo",
            "type": "User",
            "format": ""
        },
        {
            "name": "inAlarmState",
            "baseName": "inAlarmState",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "inAlarmStateSince",
            "baseName": "inAlarmStateSince",
            "type": "number",
            "format": "date-time"
        },
        {
            "name": "alarmDurationInSeconds",
            "baseName": "alarmDurationInSeconds",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "QueueTypeEnum",
            "format": ""
        },
        {
            "name": "userRoleAssociations",
            "baseName": "userRoleAssociations",
            "type": "Array<UserRoleAssociation>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Queue.attributeTypeMap;
    }

    public constructor() {
    }
}


export type QueueTypeEnum = "Default" | "KK" ;


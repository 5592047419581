/**
 * Ique Api
 * Ique Api
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Priority } from './Priority';
import { HttpFile } from '../http/http';

export class KKData {
    'procedure': KKDataProcedureEnum;
    'section': string;
    'room': string;
    'para': number;
    'bmi': number;
    'openCentimeters': number;
    'healthyBeforePregnancy': boolean;
    'medications': boolean;
    'allergy': boolean;
    'pregnancy': KKDataPregnancyEnum;
    'timeOfLastLabTest'?: number;
    'secondsSinceLastLabTest'?: number;
    'expiredLabTest'?: boolean;
    'requireLabTest'?: boolean;
    'timeOfRequestToPreparePatient'?: number;
    'preparePatient'?: KKDataPreparePatientEnum;
    'secondsToPrepareForProcedure'?: number;
    'secondsUntilProcedure'?: number;
    'preparePatientConfirmed'?: boolean;
    'priority'?: Priority;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "procedure",
            "baseName": "procedure",
            "type": "KKDataProcedureEnum",
            "format": ""
        },
        {
            "name": "section",
            "baseName": "section",
            "type": "string",
            "format": ""
        },
        {
            "name": "room",
            "baseName": "room",
            "type": "string",
            "format": ""
        },
        {
            "name": "para",
            "baseName": "para",
            "type": "number",
            "format": ""
        },
        {
            "name": "bmi",
            "baseName": "bmi",
            "type": "number",
            "format": ""
        },
        {
            "name": "openCentimeters",
            "baseName": "openCentimeters",
            "type": "number",
            "format": ""
        },
        {
            "name": "healthyBeforePregnancy",
            "baseName": "healthyBeforePregnancy",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "medications",
            "baseName": "medications",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "allergy",
            "baseName": "allergy",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "pregnancy",
            "baseName": "pregnancy",
            "type": "KKDataPregnancyEnum",
            "format": ""
        },
        {
            "name": "timeOfLastLabTest",
            "baseName": "timeOfLastLabTest",
            "type": "number",
            "format": "date-time"
        },
        {
            "name": "secondsSinceLastLabTest",
            "baseName": "secondsSinceLastLabTest",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "expiredLabTest",
            "baseName": "expiredLabTest",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "requireLabTest",
            "baseName": "requireLabTest",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "timeOfRequestToPreparePatient",
            "baseName": "timeOfRequestToPreparePatient",
            "type": "number",
            "format": "date-time"
        },
        {
            "name": "preparePatient",
            "baseName": "preparePatient",
            "type": "KKDataPreparePatientEnum",
            "format": ""
        },
        {
            "name": "secondsToPrepareForProcedure",
            "baseName": "secondsToPrepareForProcedure",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "secondsUntilProcedure",
            "baseName": "secondsUntilProcedure",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "preparePatientConfirmed",
            "baseName": "preparePatientConfirmed",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "priority",
            "baseName": "priority",
            "type": "Priority",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return KKData.attributeTypeMap;
    }

    public constructor() {
    }
}


export type KKDataProcedureEnum = "Epidural" | "Spinal" ;
export type KKDataPregnancyEnum = "Normal_Pregnancy" | "Pregnancy_Induced_Hypertension" | "Preeclampsia" | "Severe_Preeclampsia" | "Other" ;
export type KKDataPreparePatientEnum = "Sitting" | "LayingDown" ;


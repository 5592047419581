import React from 'react';
import { Route, Routes } from 'react-router-dom';
import App from './App';
import Login from './pages/Login';
import RequireAuth from './components/RequireAuth';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={
          <RequireAuth>
            <App/>
          </RequireAuth>
        }
      />
      <Route path="/auth" element={<Login/>} />
    </Routes>
  );
};

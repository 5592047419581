import { AuthProviderConfig, initReactQueryAuth } from 'react-query-auth';
import { User } from 'ique-api';
import { login, logout } from '../api/auth';
import { iqueApi } from '../api/apiConfig';

interface Error {
  message: string;
}

export type LoginCredentials = {
  username: string;
  password: string;
};

type RegisterCredentials = Record<string, string>; // Not used

async function loadUser(): Promise<User | undefined> {
  try {
    return await iqueApi.getMe()
  } catch {
    return undefined;
  }
}

const loginFn = (cred: LoginCredentials): Promise<User> => {
  return login(cred)
    .then(() => iqueApi.getMe());
};

const logoutFn = () => {
  // logout and reload page if successful
  return logout().then(() => window.location.assign(window.location.origin as string));
};

const authConfig: AuthProviderConfig<User | undefined, Error> = {
  loadUser,
  loginFn,
  logoutFn,
  registerFn: () => Promise.reject("Not implemented")
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  User | undefined,
  Error,
  LoginCredentials,
  RegisterCredentials
>(authConfig);

/**
 * Ique Api
 * Ique Api
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class Event {
    'id': string;
    'creationTime': number;
    'username': string;
    'queueId': string;
    'queueItemId'?: string;
    'description': string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "creationTime",
            "baseName": "creationTime",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "username",
            "baseName": "username",
            "type": "string",
            "format": ""
        },
        {
            "name": "queueId",
            "baseName": "queueId",
            "type": "string",
            "format": ""
        },
        {
            "name": "queueItemId",
            "baseName": "queueItemId",
            "type": "string",
            "format": ""
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Event.attributeTypeMap;
    }

    public constructor() {
    }
}


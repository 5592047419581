import React, { PropsWithChildren } from 'react';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './lib/auth';
import { NotificationsProvider } from '@mantine/notifications';

const queryClient = new QueryClient();

// https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/default-theme.ts
const theme: MantineThemeOverride = {
  // TODO set colors
};

const AppProviders = ({ children }: PropsWithChildren<any>) => {
  return (
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <NotificationsProvider >
              {children}
            </NotificationsProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </MantineProvider>
  )
};

export default AppProviders;

import React from 'react';
import { ActionIcon } from '@mantine/core';
import { MdEdit, MdEditOff } from 'react-icons/md';
import { QueueItem } from 'ique-api';
import { getPriorityColor } from '../../../QueueItemBox';

type ItemEditButtonProps = {
  isEditable: boolean;
  item: QueueItem;
  onClick: (isEditable: boolean) => void;
};

const ItemEditButton = ({ item, onClick, isEditable }: ItemEditButtonProps) => {
  const handleClick = () => onClick(!isEditable);

  return (
    <ActionIcon
      color={getPriorityColor(item, true)}
      radius="xl"
      variant="filled"
      ml="md"
      onClick={handleClick}
    >
      {isEditable ? <MdEditOff size={20} /> : <MdEdit size={20} />}
    </ActionIcon>
  );
};

export default ItemEditButton;

import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Group, PasswordInput, Select } from '@mantine/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from 'ique-api';
import { LoginCredentials, useAuth } from '../../../../lib/auth';

type Inputs = LoginCredentials;

const AUTH_REGEX = RegExp('^/auth$');

// TODO same as the hardcoded users on backend
const USERS: Array<User> = [
  {
    username: 'admin',
    firstName: 'Super',
    lastName: 'Admin',
    globalRoles: ['']
  },
  {
    username: 'helenanarkos',
    firstName: 'Helena',
    lastName: 'Narkosläkare',
    globalRoles: ['']
  },
  {
    username: 'helenabm',
    firstName: 'Helena',
    lastName: 'Barnmorska',
    globalRoles: ['']
  },
  {
    username: 'helenaatlakare',
    firstName: 'Helena',
    lastName: 'AT-Läkare',
    globalRoles: ['']
  },
  {
    username: 'mariabm',
    firstName: 'Maria',
    lastName: 'Barnmorska',
    globalRoles: ['']
  },
  {
    username: 'marialedningslakare',
    firstName: 'Maria',
    lastName: 'Ledningsläkare',
    globalRoles: ['']
  },
  {
    username: 'mariaatlakare',
    firstName: 'Maria',
    lastName: 'AT-Läkare',
    globalRoles: ['']
  },
  {
    username: 'helenanarkos_sms',
    firstName: 'Helena',
    lastName: 'Narkosläkare',
    globalRoles: ['']
  },
  {
    username: 'helenabm_sms',
    firstName: 'Helena',
    lastName: 'Barnmorska',
    globalRoles: ['']
  },
  {
    username: 'helenaatlakare_sms',
    firstName: 'Helena',
    lastName: 'AT-Läkare',
    globalRoles: ['']
  },
  {
    username: 'mariabm_sms',
    firstName: 'Maria',
    lastName: 'Barnmorska',
    globalRoles: ['']
  },
  {
    username: 'marialedningslakare_sms',
    firstName: 'Maria',
    lastName: 'Ledningsläkare',
    globalRoles: ['']
  },
  {
    username: 'mariaatlakare_sms',
    firstName: 'Maria',
    lastName: 'AT-Läkare',
    globalRoles: ['']
  }
];

const LoginForm = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError
  } = useForm<Inputs>();
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Don't redirect back to form if that was the starting point.
  const from = ((location.state as any)?.from?.pathname || '/').replace(AUTH_REGEX, '/');

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    login(data)
      .then(() => {
        navigate(from, { replace: true });
      })
      .catch(() => {
        setError('username', { type: 'manual' }, { shouldFocus: true });
      });
  };

  const currentPassword = watch('password') || '';
  const isSubmitDisabled: boolean = currentPassword.length === 0;

  return (
    <Group>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Group direction="column" grow>
          <Controller
            name="username"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                label="Användare"
                placeholder="Välj..."
                error={errors.username && 'Användare eller lösenord är fel'}
                data={USERS.map((u) => ({
                  label: `${u.firstName} ${u.lastName} (${u.username})`,
                  value: u.username
                }))}
                {...field}
              />
            )}
          />

          <PasswordInput label="Lösenord" {...register('password', { required: true })} />

          <Button type="submit" disabled={isSubmitDisabled}>
            Logga in
          </Button>
        </Group>
      </form>
    </Group>
  );
};

export default LoginForm;

import { useMutation, useQueryClient } from 'react-query';
import { iqueApi } from './apiConfig';
import { QueueItem } from 'ique-api';
import { useNotifications } from '@mantine/notifications';

const updateRankOfQueueItem = (data: UseUpdateRank): Promise<QueueItem> => {
  return iqueApi.updateRank(data.queueId, data.itemId, data.lowerRank, data.upperRank);
};

type UseUpdateRank = {
  queueId: string;
  itemId: string;
  lowerRank?: string;
  upperRank?: string;
  itemsChanged: Array<QueueItem>;
};

type UseUpdateRankContext = {
  oldQueue: Array<QueueItem> | undefined;
  updatedQueue: Array<QueueItem>;
  queueId: string;
};

export const useUpdateRankOfQueueItem = () => {
  const queryClient = useQueryClient();
  const notifications = useNotifications();

  return useMutation(updateRankOfQueueItem, {
    onMutate: async (updateRankData): Promise<UseUpdateRankContext> => {
      const queryKey = ['queues/item', updateRankData.queueId];
      // Cancel any outgoing refetches (so they don't overwrite the optimistic update)
      await queryClient.cancelQueries(queryKey);
      // Snapshot the old list
      const oldQueue = queryClient.getQueryData<Array<QueueItem>>(queryKey);
      // Optimistically update
      queryClient.setQueryData(queryKey, updateRankData.itemsChanged);

      return { oldQueue, updatedQueue: updateRankData.itemsChanged, queueId: updateRankData.queueId };
    },
    onError: (err, updatedItem, context) => {
      const ctx = context as UseUpdateRankContext;
      // If update fails, reset with the old list
      queryClient.setQueryData(['queues/item', ctx.queueId], ctx.oldQueue);
      notifications.showNotification({
        color: 'red',
        message: 'Sortering av kö misslyckades'
      });
    },
    onSettled: (updatedItem) => {
      // Finally, always refetch as cleanup to avoid entering an unexpected state
      queryClient.invalidateQueries(['queues/item', updatedItem?.queueId]);
    }
  });
};

import React, { PropsWithChildren } from 'react';
import { Fab } from 'react-tiny-fab';
import { useMantineTheme } from '@mantine/core';

type FabProps = {
  icon?: React.ReactNode;
  onClick?: (e: React.FormEvent) => void;
};

const FabComp = ({ children, ...rest }: PropsWithChildren<FabProps>) => {
  const theme = useMantineTheme();
  const event = children ? 'hover' : 'click';

  return (
    <Fab
      event={event}
      mainButtonStyles={{
        background: theme.colors.blue[6]
      }}
      {...rest}
    >
      {children}
    </Fab>
  )
}

export default FabComp;

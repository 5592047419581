import { useMutation, useQueryClient } from 'react-query';
import { QueueItem } from 'ique-api';
import { iqueApi } from './apiConfig';

const updateQueueItem = (item: QueueItem) => {
  return iqueApi.updateItem(item.queueId, item.id, item);
};

export const useUpdateQueueItem = () => {
  const queryClient = useQueryClient();

  return useMutation(updateQueueItem, {
    onSuccess: (item) => {
      queryClient.invalidateQueries(['queues/item', item.queueId]);
      queryClient.invalidateQueries(['queues/itemsInProgress', item.queueId]);
    }
  });
};

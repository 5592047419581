import React, { forwardRef, useEffect, useState } from 'react';
import {
  AppShell,
  Avatar,
  Box,
  Burger,
  Group,
  Header,
  Image,
  MediaQuery,
  Menu,
  Navbar,
  Space,
  Text,
  UnstyledButton,
  UnstyledButtonProps
} from '@mantine/core';
import QueuePage from './pages/QueuePage';
import { MdLogout } from 'react-icons/md';
import { useAuth } from './lib/auth';
import { useQueueInfoList } from './api/getQueueInfoList';
import { Queue } from 'ique-api';
import { useEvents } from './api/getEvents';

// eslint-disable-next-line react/display-name
const UserMenuButton = forwardRef<HTMLButtonElement, UnstyledButtonProps>(({ ...props }, ref) => {
  const { user } = useAuth();
  return (
    <UnstyledButton ref={ref} {...props}>
      <Group>
        <Box sx={() => ({ color: '#fff' })}>
          <Text size="sm">{`${user?.firstName} ${user?.lastName}`}</Text>
          <Text size="xs">{`${user?.username}`}</Text>
        </Box>
        <Avatar radius="xl">{`${user?.firstName[0]}${user?.lastName[0]}`}</Avatar>
      </Group>
    </UnstyledButton>
  );
});

const App = () => {
  const [opened, setOpened] = useState(false);
  const { logout } = useAuth();
  const queueInfoList = useQueueInfoList();
  const events = useEvents();
  const [queueSelectedId, setQueueSelectedId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (queueSelectedId === undefined && queueInfoList.data && queueInfoList.data.length > 0) {
      setQueueSelectedId(queueInfoList.data[0].id);
    }
  }, [queueInfoList.data]);

  useEffect(() => {
    if (events?.data?.items) {
      events?.data?.items.forEach((item) => {
        //notifyMe(item?.description);
      });
      console.log(events.data);
      new Audio('notification-new.mp3').play();
    }
  }, [events?.data?.items]);

  const notifyMe = (description: string) => {
    if (!('Notification' in window)) {
      // Notifications not supported
    } else if (Notification.permission === 'granted') {
      new Notification(description);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          new Notification(description);
        }
      });
    }
  };

  const handleMenuItemClick = (itemId: string) => {
    setQueueSelectedId(itemId);
    setOpened(false);
  };

  return (
    <AppShell
      padding={0}
      sx={(theme) => ({
        backgroundColor: theme.colors.gray[0]
      })}
      navbarOffsetBreakpoint="md"
      fixed
      navbar={
        <Navbar padding={0} hiddenBreakpoint="md" hidden={!opened} width={{ base: 300 }}>
          <Space h="md" />
          {queueInfoList.data?.map((item: Queue) => (
            <Box
              sx={(theme) =>
                queueSelectedId === item.id
                  ? {
                      padding: theme.spacing.md,
                      backgroundColor: theme.colors.blue[1],
                      cursor: 'pointer'
                    }
                  : {
                      padding: theme.spacing.md,
                      backgroundColor: '#0000',
                      '&:hover': { backgroundColor: theme.colors.gray[2] },
                      cursor: 'pointer'
                    }
              }
              onClick={() => handleMenuItemClick(item.id)}
              key={item.id}
            >
              {item.displayName}
            </Box>
          ))}
        </Navbar>
      }
      header={
        <Header
          height={60}
          sx={(theme) => ({
            backgroundColor: '#4d90cbff', // TODO get from theme
            paddingLeft: theme.spacing.md,
            paddingRight: theme.spacing.md
          })}
        >
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <MediaQuery largerThan="md" styles={{ display: 'none' }}>
              <Burger opened={opened} onClick={() => setOpened((o) => !o)} size="sm" color="white" />
            </MediaQuery>

            <div style={{ flexGrow: '1' }}>
              <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
                <Image width={120} src="Ique-logo.svg" alt="Ique Logo" />
              </MediaQuery>
            </div>

            <Group position="center">
              <Menu placement="end" control={<UserMenuButton />}>
                <Menu.Item icon={<MdLogout />} onClick={() => logout()}>
                  Logga ut
                </Menu.Item>
              </Menu>
            </Group>
          </div>
        </Header>
      }
    >
      {queueSelectedId && <QueuePage queueId={queueSelectedId} />}
    </AppShell>
  );
};

export default App;

import { useQuery } from 'react-query';
import { QueueItem } from 'ique-api';
import { iqueApi } from './apiConfig';

const getList = (id: string, inProgress?: boolean): Promise<QueueItem[]> => {
  return iqueApi.getItemsForList(id, inProgress);
};

type UseListsOptions = {
  queueId: string;
};

export const useQueueItemList = ({ queueId }: UseListsOptions) => {
  return useQuery<QueueItem[], Error>({
    queryKey: ['queues/item', queueId],
    queryFn: () => getList(queueId),
    refetchInterval: 10000,
    placeholderData: []
  });
};

export const useInProgressQueueItemList = ({ queueId }: UseListsOptions) => {
  return useQuery<QueueItem[], Error>({
    queryKey: ['queues/itemsInProgress', queueId],
    queryFn: () => getList(queueId, true),
    refetchInterval: 10000,
    placeholderData: []
  });
};

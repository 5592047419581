import { basePath } from '../Util/ApiUtil';
import { fetchRejectOnHttpError } from './apiConfig';

export const login = (item: Record<string, string>) => {
  return fetchRejectOnHttpError(`${basePath()}/login`, {
    method: 'POST',
    body: JSON.stringify(item),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });
}

export const logout = () => {
  return fetchRejectOnHttpError(`${basePath()}/logout`, {
    method: 'POST',
    credentials: 'include'
  });
}

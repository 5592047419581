import React from 'react';
import { PropsWithChildren } from 'react';
import { useAuth } from '../../lib/auth';
import { Role } from '../../model/roles';

type RequiredRoleOrIsOwnerProps = PropsWithChildren<{
  roles?: Role[];
  queueId?: string;
  owner?: string;
}>;

const RequireRoleOrIsOwner = ({ children, roles, queueId, owner }: RequiredRoleOrIsOwnerProps) => {
  const { user } = useAuth();
  const hasRole =
    user?.queueRoles
      ?.filter((item) => item.queueId === queueId)
      .map((item) => item.role)
      .find((item) => roles?.find((acceptableRole) => acceptableRole === item)) !== undefined;

  const isOwner = user?.username === owner;

  return <>{hasRole || isOwner ? children : null}</>;
};

export default RequireRoleOrIsOwner;

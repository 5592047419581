import React, { useEffect, useState } from 'react';
import { ActionIcon, Box, Container, Group, Text, Title } from '@mantine/core';
import { MdClose } from 'react-icons/md';
import { QueueItem } from 'ique-api';
import { getPriorityColor } from '../QueueItemBox';
import QueueItemForm from '../QueueItemForm';
import { useUpdateQueueItem } from '../../../../api/updateQueueItem';
import ItemDeleteButton from './components/ItemDeleteButton';
import QueueItemDetails from '../QueueItemDetails';
import RequireRoleOrIsOwner from '../../../../components/RequireRoleOrIsOwner';
import ItemEditButton from './components/ItemEditButton';
import QueueItemDefaultForm from '../QueueItemDefaultForm';
import QueueItemDefaultDetails from '../QueueItemDefaultDetails';

type ItemDetailsPanelProps = {
  onSelectQueueItem: (item: QueueItem | undefined) => void;
  queueItemSelected: QueueItem;
};

const ItemDetailsPanel = ({ onSelectQueueItem, queueItemSelected }: ItemDetailsPanelProps) => {
  const updateItem = useUpdateQueueItem();
  const [isEditable, setEditable] = useState(false);

  useEffect(() => {
    setEditable(false);
  }, [queueItemSelected.id]);

  const handleDelete = (item?: QueueItem) => {
    if (item) {
      updateItem.mutate({
        ...item,
        deleted: true
      });
      onSelectQueueItem(undefined);
    }
  };

  return (
    <Container padding={0} sx={{ backgroundColor: 'white' }}>
      <Box
        mb="sm"
        sx={(theme) => ({
          padding: theme.spacing.sm,
          backgroundColor: getPriorityColor(queueItemSelected)
        })}
      >
        <div style={{ display: 'flex' }}>
          <ActionIcon
            color={getPriorityColor(queueItemSelected, true)}
            radius="xl"
            variant="filled"
            mr="md"
            onClick={() => onSelectQueueItem(undefined)}
          >
            <MdClose size={20} />
          </ActionIcon>
          <Group m={0} spacing="sm">
            <Title order={3} sx={(theme) => ({ color: theme.colors.gray[0] })}>
              {queueItemSelected.displayName}
            </Title>
            {queueItemSelected.kkData && queueItemSelected.kkData.pregnancy !== 'Normal_Pregnancy' && (
              <Text sx={(theme) => ({ color: theme.colors.gray[0], alignSelf: 'center' })}>Läs journal</Text>
            )}
          </Group>
          <div style={{ flexGrow: '1' }} />
          <RequireRoleOrIsOwner owner={queueItemSelected.createdBy}>
            {queueItemSelected && (
              <ItemEditButton isEditable={isEditable} onClick={setEditable} item={queueItemSelected} />
            )}
            <ItemDeleteButton item={queueItemSelected} onDelete={handleDelete} />
          </RequireRoleOrIsOwner>
        </div>
      </Box>

      <Box sx={(theme) => ({ paddingLeft: theme.spacing.md, paddingRight: theme.spacing.md })}>
        {queueItemSelected.kkData ? (
          isEditable ? (
            <QueueItemForm itemData={queueItemSelected} onSubmitted={() => setEditable(false)} />
          ) : (
            <QueueItemDetails item={queueItemSelected} onSelectQueueItem={onSelectQueueItem} />
          )
        ) : isEditable ? (
          <QueueItemDefaultForm itemData={queueItemSelected} onSubmitted={() => setEditable(false)} />
        ) : (
          <QueueItemDefaultDetails item={queueItemSelected} onSelectQueueItem={onSelectQueueItem} />
        )}
      </Box>
    </Container>
  );
};

export default ItemDetailsPanel;

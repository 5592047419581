/**
 * Ique Api
 * Ique Api
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { KKData } from './KKData';
import { Priority } from './Priority';
import { HttpFile } from '../http/http';

export class QueueItem {
    'id': string;
    'createdBy': string;
    'rank': string;
    'queueId': string;
    'displayName': string;
    'creationTime'?: number;
    'secondsInQueue': number;
    'confirmed'?: boolean;
    'done'?: boolean;
    'deleted'?: boolean;
    'paused'?: boolean;
    'inProgress'?: boolean;
    'callBefore': boolean;
    'phoneNumber'?: string;
    'priority'?: Priority;
    'kkData'?: KKData;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createdBy",
            "baseName": "createdBy",
            "type": "string",
            "format": ""
        },
        {
            "name": "rank",
            "baseName": "rank",
            "type": "string",
            "format": ""
        },
        {
            "name": "queueId",
            "baseName": "queueId",
            "type": "string",
            "format": ""
        },
        {
            "name": "displayName",
            "baseName": "displayName",
            "type": "string",
            "format": ""
        },
        {
            "name": "creationTime",
            "baseName": "creationTime",
            "type": "number",
            "format": "date-time"
        },
        {
            "name": "secondsInQueue",
            "baseName": "secondsInQueue",
            "type": "number",
            "format": "int64"
        },
        {
            "name": "confirmed",
            "baseName": "confirmed",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "done",
            "baseName": "done",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "deleted",
            "baseName": "deleted",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "paused",
            "baseName": "paused",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "inProgress",
            "baseName": "inProgress",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "callBefore",
            "baseName": "callBefore",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "phoneNumber",
            "baseName": "phoneNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "priority",
            "baseName": "priority",
            "type": "Priority",
            "format": ""
        },
        {
            "name": "kkData",
            "baseName": "kkData",
            "type": "KKData",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return QueueItem.attributeTypeMap;
    }

    public constructor() {
    }
}


import { EventResponse } from 'ique-api';
import { iqueApi } from './apiConfig';
import { useQuery } from 'react-query';
import { useContinuationStore } from '../store/continuations';

const getEvents = (continuationToken: string | undefined): Promise<EventResponse> => {
  return iqueApi.getEvents(continuationToken);
};

export const useEvents = () => {
  const continuationStore = useContinuationStore();

  return useQuery<EventResponse, Error>({
    queryKey: ['events'],
    queryFn: () => getEvents(continuationStore.continuations.events),
    refetchInterval: 10000,
    placeholderData: undefined,
    onSuccess: (data) => {
      continuationStore.updateContinuation({ events: data.continuationToken });
    }
  });
};

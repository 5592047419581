import React, { useState } from 'react';
import { ActionIcon, Button, Group, Popover, Text } from '@mantine/core';
import { MdDelete } from 'react-icons/md';
import { QueueItem } from 'ique-api';
import { getPriorityColor } from '../../../QueueItemBox';

type ItemDeleteButtonProps = {
  item?: QueueItem;
  onDelete: (item: QueueItem) => void;
};

const ItemDeleteButton = ({ item, onDelete }: ItemDeleteButtonProps) => {
  const [opened, setOpened] = useState(false);

  const handleClose = () => setOpened(false);

  const handleOK = () => {
    if (item) {
      onDelete(item);
    }
    handleClose();
  };

  return (
    <Popover
      opened={opened}
      onClose={handleClose}
      target={
        <ActionIcon
          color={getPriorityColor(item, true)}
          radius="xl"
          variant="filled"
          ml="md"
          onClick={() => setOpened(o => !o)}
        >
          <MdDelete size={20} />
        </ActionIcon>
      }
      width={300}
      position="bottom"
      placement="end"
      gutter={5}
      withArrow
    >
      <Text>Vill du ta bort denna beställning?</Text>
      <Group mt="sm" grow>
        <Button onClick={handleClose} variant="outline" data-autofocus>Nej</Button>
        <Button onClick={handleOK}>Ja</Button>
      </Group>
    </Popover>
  );
};

export default ItemDeleteButton;

import { useQuery } from 'react-query';
import { Queue } from 'ique-api';
import { iqueApi } from './apiConfig';

const getList = (): Promise<Queue[]> => {
  return iqueApi.getAllQueues();
};

export const useQueueInfoList = () => {
  return useQuery<Queue[], Error>({
    queryKey: ['queues/info'],
    queryFn: () => getList(),
    refetchInterval: 10000,
    placeholderData: []
  });
};

import React from 'react';
import { Button, ButtonProps } from '@mantine/core';
import { QueueItem } from 'ique-api';
import { Role } from '../../../../model/roles';
import RequireRoleOrIsOwner from '../../../../components/RequireRoleOrIsOwner';

type ItemActionButtonProps = ButtonProps<'button'> & {
  item: QueueItem;
  roles?: Role[];
  owner?: string;
};

const ItemActionButton = ({ children, disabled, item, roles, owner, ...rest }: ItemActionButtonProps) => {
  return (
    <RequireRoleOrIsOwner queueId={item.queueId} roles={roles} owner={owner}>
      <Button disabled={disabled || item.paused} {...rest}>
        {children}
      </Button>
    </RequireRoleOrIsOwner>
  );
};

export default ItemActionButton;

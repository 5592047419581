import React, { useEffect, useState } from 'react';
import { ActionIcon, Button, Center, Checkbox, CloseButton, Container, Grid, Group, Text, Title } from '@mantine/core';
import dayjs from 'dayjs';
import { KKDataPreparePatientEnum, QueueItem } from 'ique-api';
import { displayNameForPregnancy, displayNameForPreparePatient } from '../../../../model/kk';
import RequireRoleOrIsOwner from '../../../../components/RequireRoleOrIsOwner';
import { ROLES } from '../../../../model/roles';
import { useNotifications } from '@mantine/notifications';
import { useUpdateQueueItem } from '../../../../api/updateQueueItem';
import { TimeInput } from '@mantine/dates';
import { MdCall } from 'react-icons/md';
import ItemActionButton from '../ItemActionButton';
import { useQueueItemList } from '../../../../api/getQueueItemList';
import { useUsers } from '../../../../api/getUsers';

type QueueItemDetailsProps = {
  item: QueueItem;
  onSelectQueueItem: (item: QueueItem | undefined) => void;
};

const time = (d?: number) => (d ? dayjs.unix(d).format('HH:mm') : '--:--');

const QueueItemDetails = ({ item, onSelectQueueItem }: QueueItemDetailsProps) => {
  const updateItem = useUpdateQueueItem();
  const notifications = useNotifications();
  const users = useUsers();
  const [showLabTestInput, setShowLabTestInput] = useState(false);
  const [selectedTime, setSelectedTime] = useState<Date | undefined>(undefined);
  const [checkedConfirmation, setCheckedConfirmation] = useState(false);

  // TODO FIX add hook?
  const waitingList = useQueueItemList({ queueId: item.queueId });
  const unpaused = (waitingList.data || []).filter((i) => i.paused !== true);
  const isFirstInLine = unpaused[0]?.id === item.id;

  useEffect(() => {
    setShowLabTestInput(false);
    setSelectedTime(undefined);
    setCheckedConfirmation(false);
  }, [item.id]);

  const onMarkAsDone = () => {
    updateItem.mutateAsync({ ...item, done: true }).then(() => {
      notifications.showNotification({
        message: 'Beställning klarmarkerad',
        color: 'green'
      });
      onSelectQueueItem(undefined);
    });
  };

  const onConfirm = () => {
    updateItem.mutateAsync({ ...item, confirmed: true }).then(() =>
      notifications.showNotification({
        message: 'Beställning bekräftad',
        color: 'green'
      })
    );
  };

  const handlePauseToggle = () => {
    updateItem.mutateAsync({ ...item, paused: !item.paused }).then(() => {
      return notifications.showNotification({
        message: `Förfrågan ${item.paused ? 'återuppdagen' : 'pausad'}`,
        color: 'green'
      });
    });
  };

  const handlePrepareSeated = () => handleUpdatePreparation('Sitting');
  const handlePrepareLaying = () => handleUpdatePreparation('LayingDown');

  const handleUpdatePreparation = (preparePatient: KKDataPreparePatientEnum) => {
    if (item.kkData) {
      updateItem
        .mutateAsync({
          ...item,
          kkData: {
            ...item.kkData,
            preparePatient
          }
        })
        .then(() => {
          return notifications.showNotification({
            message: `Patient ska förberedas ${displayNameForPreparePatient(preparePatient)}`,
            color: 'green'
          });
        });
    }
  };

  const handleConfirmPreparePatient = () => {
    if (item.kkData) {
      updateItem
        .mutateAsync({
          ...item,
          kkData: {
            ...item.kkData,
            preparePatientConfirmed: true
          }
        })
        .then(() => {
          return notifications.showNotification({
            message: `Förbered ${displayNameForPreparePatient(item?.kkData?.preparePatient)} har bekräftats`,
            color: 'green'
          });
        });
    }
  };

  const onLabTestUpdate = () => {
    updateItem
      .mutateAsync({
        ...item,
        kkData: {
          ...item.kkData,
          timeOfLastLabTest: selectedTime
        }
      } as QueueItem)
      .then(() =>
        notifications.showNotification({
          message: 'Labbtest uppdaterad',
          color: 'green'
        })
      );
    setShowLabTestInput(false);
  };

  const { kkData } = item;
  const reporter = users.data?.find((u) => u.username === item.createdBy);
  const reporterString = reporter
    ? `${reporter.firstName} ${reporter.lastName} (${reporter.username})`
    : item.createdBy;
  const mustConfirmAction = !item.inProgress && !item.paused && item.confirmed && !isFirstInLine;
  const actionButtonDisabled = item.paused || (mustConfirmAction && !checkedConfirmation);

  return (
    <Container padding={0}>
      {kkData && (
        <Grid gutter="xs">
          <Grid.Col span={2}>
            <Title order={5}>{'Para:'}</Title>
          </Grid.Col>
          <Grid.Col span={2}>
            <Text>{kkData.para}</Text>
          </Grid.Col>
          <Grid.Col span={2}>
            <Title order={5}>{'BMI:'}</Title>
          </Grid.Col>
          <Grid.Col span={2}>
            <Text>{kkData.bmi}</Text>
          </Grid.Col>
          <Grid.Col span={2}>
            <Title order={5}>{'Öppen:'}</Title>
          </Grid.Col>
          <Grid.Col span={2}>
            <Text>{`${kkData.openCentimeters} cm`}</Text>
          </Grid.Col>

          <Grid.Col span={12}>
            <Text color={kkData.pregnancy === 'Normal_Pregnancy' ? undefined : 'red'}>
              {displayNameForPregnancy(kkData.pregnancy)}
            </Text>
          </Grid.Col>

          <Grid.Col span={6}>
            <Title order={5}>{'Labbprover (kl):'}</Title>
          </Grid.Col>
          <Grid.Col span={6}>
            <Group>
              <Text color={item.kkData?.expiredLabTest ? 'red' : undefined}>{time(kkData.timeOfLastLabTest)}</Text>
              {showLabTestInput ? (
                <Group>
                  <TimeInput
                    size="xs"
                    value={selectedTime}
                    onChange={setSelectedTime}
                    rightSection={<CloseButton onClick={() => setShowLabTestInput(false)} />}
                  />
                  <Button size="xs" onClick={onLabTestUpdate}>
                    Spara
                  </Button>
                </Group>
              ) : (
                <RequireRoleOrIsOwner owner={item.createdBy}>
                  <Button
                    size="xs"
                    variant={item.kkData?.expiredLabTest ? 'filled' : 'outline'}
                    onClick={() => setShowLabTestInput(true)}
                  >
                    Ta nya
                  </Button>
                </RequireRoleOrIsOwner>
              )}
            </Group>
          </Grid.Col>

          <Grid.Col span={6}>
            <Title order={5}>{'Frisk före graviditet:'}</Title>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text color={kkData.healthyBeforePregnancy ? undefined : 'red'}>
              {kkData.healthyBeforePregnancy ? 'Ja' : 'Nej'}
            </Text>
          </Grid.Col>

          <Grid.Col span={6}>
            <Title order={5}>{'Allergi:'}</Title>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text color={kkData.allergy ? 'red' : undefined}>{kkData.allergy ? 'Ja' : 'Nej'}</Text>
          </Grid.Col>

          <Grid.Col span={6}>
            <Title order={5}>{'Mediciner:'}</Title>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text color={kkData.medications ? 'red' : undefined}>{kkData.medications ? 'Ja' : 'Nej'}</Text>
          </Grid.Col>

          <Grid.Col span={6}>
            <Title order={5}>{'Anmäld:'}</Title>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text>{`${time(item.creationTime)} av ${reporterString}`}</Text>
          </Grid.Col>
          {item.callBefore && (
            <Grid.Col span={12}>
              <Center>
                <ActionIcon<'a'>
                  component="a"
                  color="red"
                  radius="xs"
                  variant="filled"
                  mr="md"
                  href={`tel:${item.phoneNumber}`}
                >
                  <MdCall size={20} />
                </ActionIcon>
                <Text weight="bold" component="span">
                  Kontakta anmälare på{' '}
                  <Text variant="link" component="a" href={`tel:${item.phoneNumber}`}>
                    {item.phoneNumber}
                  </Text>
                </Text>
              </Center>
            </Grid.Col>
          )}
        </Grid>
      )}

      <Group direction="column" mt="md" grow>
        {item?.kkData?.preparePatient && (
          <Text>Patienten ska förberedas {displayNameForPreparePatient(item?.kkData.preparePatient)}</Text>
        )}

        <RequireRoleOrIsOwner owner={item.createdBy}>
          <Button variant="outline" fullWidth onClick={handlePauseToggle}>
            {item.paused ? 'Återuppta förfrågan' : 'Pausa förfrågan'}
          </Button>
        </RequireRoleOrIsOwner>

        {mustConfirmAction && (
          <RequireRoleOrIsOwner roles={['QUEUE_ADMIN']} queueId={item.queueId}>
            <Checkbox
              label="Denna beställning ligger inte först i kön. Kryssa i rutan för att bekräfta att du vill agera."
              checked={checkedConfirmation}
              onChange={(e) => setCheckedConfirmation(e.currentTarget.checked)}
            />
          </RequireRoleOrIsOwner>
        )}

        {item.confirmed && (
          <RequireRoleOrIsOwner roles={[ROLES.ADMIN, ROLES.QUEUE_ADMIN]} queueId={item.queueId}>
            <Group grow>
              <Button
                variant={item.kkData?.preparePatient ? 'outline' : 'filled'}
                fullWidth
                onClick={handlePrepareSeated}
                disabled={actionButtonDisabled || item.kkData?.preparePatient === 'Sitting'}
              >
                Förbered sittande
              </Button>
              <Button
                variant={item.kkData?.preparePatient ? 'outline' : 'filled'}
                fullWidth
                onClick={handlePrepareLaying}
                disabled={actionButtonDisabled || item.kkData?.preparePatient === 'LayingDown'}
              >
                Förbered liggande
              </Button>
            </Group>
          </RequireRoleOrIsOwner>
        )}

        {item?.kkData?.preparePatient && !item?.kkData?.preparePatientConfirmed && (
          <ItemActionButton item={item} owner={item.createdBy} fullWidth onClick={handleConfirmPreparePatient}>
            Bekräfta förbered {displayNameForPreparePatient(item.kkData.preparePatient)}
          </ItemActionButton>
        )}

        {item.confirmed ? (
          <ItemActionButton
            item={item}
            roles={[ROLES.ADMIN, ROLES.QUEUE_ADMIN]}
            owner={item.createdBy}
            fullWidth
            variant={item.inProgress && item?.kkData?.preparePatientConfirmed ? 'filled' : 'outline'}
            onClick={onMarkAsDone}
          >
            Markera som klar
          </ItemActionButton>
        ) : (
          <ItemActionButton item={item} roles={[ROLES.ADMIN, ROLES.QUEUE_ADMIN]} fullWidth onClick={onConfirm}>
            Bekräfta
          </ItemActionButton>
        )}
      </Group>
    </Container>
  );
};

export default QueueItemDetails;

/**
 * Ique Api
 * Ique Api
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Event } from './Event';
import { HttpFile } from '../http/http';

export class EventResponse {
    'continuationToken': string;
    'items': Array<Event>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "continuationToken",
            "baseName": "continuationToken",
            "type": "string",
            "format": ""
        },
        {
            "name": "items",
            "baseName": "items",
            "type": "Array<Event>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return EventResponse.attributeTypeMap;
    }

    public constructor() {
    }
}


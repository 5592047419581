import create from 'zustand';

export type Continuations = {
  events: string | undefined;
};

type ContinuationsStore = {
  continuations: Continuations;
  updateContinuation: (update: Partial<Continuations>) => void;
};

export const useContinuationStore = create<ContinuationsStore>((set) => ({
  continuations: { events: undefined },
  updateContinuation: (update) => {
    set((state) => ({
      continuations: { ...state.continuations, ...update }
    }));
  }
}));

import React from 'react';
import { Box, Center, Group, MantineColor, Paper, Text, ThemeIcon, useMantineTheme } from '@mantine/core';
import { QueueItem } from 'ique-api';
import { useAuth } from '../../../../lib/auth';
import { ROLES } from '../../../../model/roles';
import { MdNotifications } from 'react-icons/md';

type QueueItemProps = {
  isSelected?: boolean;
  queuePos?: number;
  queueItem: QueueItem;
  onClick?: (queueItem: QueueItem) => void;
};

const secondsToMinutes = (seconds: number) => {
  return Math.max(0, Math.ceil(seconds / 60));
};

export const getPriorityColor = (queueItem: QueueItem | undefined, asMantineColor = false): MantineColor | string => {
  const theme = useMantineTheme();

  if (queueItem?.paused) {
    return asMantineColor ? 'gray' : theme.colors.gray[6];
  } else if (queueItem?.priority === 'Red') {
    return asMantineColor ? 'red' : theme.colors.red[6];
  } else if (queueItem?.priority === 'Orange') {
    return asMantineColor ? 'orange' : theme.colors.orange[6];
  } else if (queueItem?.priority === 'Green') {
    return asMantineColor ? 'green' : theme.colors.green[6];
  } else {
    return asMantineColor ? 'gray' : theme.colors.gray[6];
  }
};

const QueueItemBox = ({ isSelected, queuePos, queueItem, onClick }: QueueItemProps) => {
  const theme = useMantineTheme();
  const { user } = useAuth();

  const userRoles = user?.globalRoles || [];
  const isNormalUser = userRoles.find((r) => r === ROLES.QUEUE_USER);
  const isQueueAdmin = userRoles.find((r) => r === ROLES.QUEUE_ADMIN);

  // TODO should use colors from theme
  const getBackgroundColor = () => {
    return queueItem?.paused ? 'lightgrey' : 'white';
  };

  const getTextColor = (fallbackColor: string) => {
    return queueItem?.paused ? 'gray' : fallbackColor;
  };

  return (
    <Paper
      padding="xs"
      sx={(theme) =>
        isSelected
          ? {
              backgroundColor: theme.colors.blue[1],
              cursor: 'pointer'
            }
          : {
              backgroundColor: '#0000',
              '&:hover': { backgroundColor: theme.colors.gray[2] },
              cursor: 'pointer'
            }
      }
    >
      <Box sx={() => ({ backgroundColor: getBackgroundColor() })}>
        <div style={{ display: 'flex', minHeight: 65 }} onClick={() => onClick && onClick(queueItem)}>
          <Box
            sx={{
              backgroundColor: getPriorityColor(queueItem),
              padding: 5,
              borderRadius: 2
            }}
          />
          <Center style={{ flexGrow: '1', padding: theme.spacing.sm }}>
            <Text color={getTextColor('black')} mr="lg">
              {queuePos}
            </Text>
            <Text color={getTextColor('blue')} sx={{ flexGrow: '1' }}>
              {queueItem.displayName}
            </Text>

            <Group spacing={0} direction="column" position="center" ml="md">
              {queueItem?.paused ? (
                <Text size="xs" color={getTextColor('red')}>
                  Pausad
                </Text>
              ) : !queueItem.confirmed ? (
                <Text size="xs" weight={isQueueAdmin ? 'bolder' : undefined} color={getTextColor('orange')}>
                  {isQueueAdmin && (
                    <ThemeIcon size="xs" variant="light" color={getTextColor('orange')} mr="xs">
                      <MdNotifications size={10} />
                    </ThemeIcon>
                  )}
                  Ej bekräftad
                </Text>
              ) : queueItem?.kkData?.preparePatient && !queueItem?.kkData?.preparePatientConfirmed ? (
                <Text size="xs" weight={isNormalUser ? 'bolder' : undefined} color={getTextColor('red')}>
                  {isNormalUser && (
                    <ThemeIcon size="xs" variant="light" color={getTextColor('red')} mr="xs">
                      <MdNotifications size={10} />
                    </ThemeIcon>
                  )}
                  Bekräfta förbered
                </Text>
              ) : (
                ''
              )}

              {queueItem?.kkData?.secondsUntilProcedure ? (
                <Text style={{ fontStyle: 'italic' }} size="xs" color={getTextColor('black')}>
                  Startar om: {secondsToMinutes(queueItem?.kkData?.secondsUntilProcedure)} min
                </Text>
              ) : (
                <Text style={{ fontStyle: 'italic' }} size="xs" color={getTextColor('black')}>
                  Tid i kö: {secondsToMinutes(queueItem.secondsInQueue)} min
                </Text>
              )}
            </Group>
          </Center>
        </div>
      </Box>
    </Paper>
  );
};

export default QueueItemBox;

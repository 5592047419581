import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Checkbox, Grid, Radio, RadioGroup, Select, Text, TextInput } from '@mantine/core';
import { KKData, QueueItem, User } from 'ique-api';
import { useAddQueueItem } from '../../../../api/addQueueItem';
import { useUpdateQueueItem } from '../../../../api/updateQueueItem';
import { useAuth } from '../../../../lib/auth';
import { displayNameForPregnancy } from '../../../../model/kk';
import { TimeInput } from '@mantine/dates';
import dayjs from 'dayjs';

type QueueItemFormProps = {
  queueId?: string;
  itemData?: QueueItem;
  onSubmitted?: () => void;
};

type Inputs = KKData & {
  callBefore: boolean;
  phoneNumber: undefined | string;
};

const PREGNANCY_SELECT_DATA = [
  { label: displayNameForPregnancy('Normal_Pregnancy'), value: 'Normal_Pregnancy' },
  { label: displayNameForPregnancy('Pregnancy_Induced_Hypertension'), value: 'Pregnancy_Induced_Hypertension' },
  { label: displayNameForPregnancy('Preeclampsia'), value: 'Preeclampsia' },
  { label: displayNameForPregnancy('Severe_Preeclampsia'), value: 'Severe_Preeclampsia' },
  { label: displayNameForPregnancy('Other'), value: 'Other' }
];

const getDefaultValues = (itemData?: QueueItem, user?: User) => ({
  ...itemData?.kkData,
  procedure: itemData?.kkData?.procedure || 'Epidural',
  callBefore: itemData?.callBefore || false,
  phoneNumber: itemData?.phoneNumber || user?.phoneNumber
});

const QueueItemForm = ({ queueId, itemData, onSubmitted }: QueueItemFormProps) => {
  const { user } = useAuth();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: getDefaultValues(itemData, user)
  });

  useEffect(() => {
    reset(getDefaultValues(itemData), { keepValues: false });
  }, [itemData?.id]);

  const addItem = useAddQueueItem();
  const updateItem = useUpdateQueueItem();

  const yesNoRadioGroup = (name: keyof Inputs, label: string) => (
    <Controller
      name={name}
      control={control}
      rules={{ validate: (v) => v !== undefined }}
      render={({ field }) => (
        <RadioGroup
          label={label}
          required
          {...field}
          value={field.value?.toString()}
          onChange={(value) => field.onChange(value === 'true')}
          error={errors[name] && 'Välj ja/nej'}
        >
          <Radio value="true">
            <Text size="sm">Ja</Text>
          </Radio>
          <Radio value="false">
            <Text size="sm">Nej</Text>
          </Radio>
        </RadioGroup>
      )}
    />
  );

  const onSubmit: SubmitHandler<Inputs> = ({ callBefore, phoneNumber, ...kkData }) => {
    if (itemData) {
      updateItem.mutate({
        ...itemData,
        displayName: `${kkData.section}:${kkData.room}`,
        callBefore,
        phoneNumber,
        kkData
      } as QueueItem);
    } else {
      addItem.mutate({
        id: '',
        createdBy: '',
        rank: '',
        queueId: queueId,
        displayName: `${kkData.section}:${kkData.room}`,
        callBefore,
        phoneNumber,
        kkData
      } as QueueItem);
    }
    onSubmitted && onSubmitted();
  };

  const [isCallBeforeEnabled, pregnancyValue] = watch(['callBefore', 'pregnancy']);
  const isLabTestRequired = pregnancyValue === 'Preeclampsia' || pregnancyValue === 'Severe_Preeclampsia';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Col span={12}>
          <Controller
            name="procedure"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <RadioGroup label="Ingrepp" {...field}>
                <Radio value="Epidural">
                  <Text size="sm">Epidural</Text>
                </Radio>
                <Radio value="Spinal">
                  <Text size="sm">Spinal</Text>
                </Radio>
              </RadioGroup>
            )}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput label="Avdelning" required error={!!errors.section} {...register('section', { required: true })} />
        </Grid.Col>

        <Grid.Col span={6}>
          <TextInput label="Rum" required error={!!errors.room} {...register('room', { required: true })} />
        </Grid.Col>

        <Grid.Col span={4}>
          <TextInput
            type="number"
            label="Para"
            required
            error={errors.para && '0 eller fler'}
            {...register('para', { required: true, valueAsNumber: true, min: 0 })}
          />
        </Grid.Col>

        <Grid.Col span={4}>
          <TextInput
            type="number"
            label="BMI"
            required
            error={errors.bmi && '0 eller mer'}
            {...register('bmi', { required: true, valueAsNumber: true, min: 0 })}
          />
        </Grid.Col>

        <Grid.Col span={4}>
          <TextInput
            type="number"
            label="Öppen (cm)"
            required
            error={errors.openCentimeters && 'Mellan 0 och 10'}
            {...register('openCentimeters', { required: true, valueAsNumber: true, min: 0, max: 10 })}
          />
        </Grid.Col>

        <Grid.Col span={12}>{yesNoRadioGroup('healthyBeforePregnancy', 'Frisk före graviditet')}</Grid.Col>
        <Grid.Col span={12}>{yesNoRadioGroup('medications', 'Mediciner')}</Grid.Col>
        <Grid.Col span={12}>{yesNoRadioGroup('allergy', 'Allergi')}</Grid.Col>

        <Grid.Col span={6}>
          <Controller
            name="pregnancy"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                label="Graviditet"
                placeholder="Välj..."
                data={PREGNANCY_SELECT_DATA}
                error={errors.pregnancy && 'Välj'}
              />
            )}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Controller
            name="timeOfLastLabTest"
            control={control}
            rules={{ required: isLabTestRequired }}
            render={({ field }) => (
              <TimeInput
                {...field}
                required={isLabTestRequired}
                label="Senaste labbprover"
                error={errors.timeOfLastLabTest && 'Ange en tid'}
                value={field.value ? dayjs.unix(field.value).toDate() : undefined}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <Checkbox label="Mottagaren ska kontakta mig" {...register('callBefore')} />
        </Grid.Col>
        {isCallBeforeEnabled && (
          <Grid.Col span={12}>
            <TextInput
              label="Telefonnummer"
              error={errors.phoneNumber && 'Ange telefonnummer'}
              {...register('phoneNumber', { required: isCallBeforeEnabled })}
            />
          </Grid.Col>
        )}

        <Grid.Col span={12}>
          <Button type="submit" fullWidth>
            {itemData ? 'Uppdatera beställning' : 'Ställ i kö'}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

export default QueueItemForm;

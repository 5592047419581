/**
 * Ique Api
 * Ique Api
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserRoleAssociation } from './UserRoleAssociation';
import { HttpFile } from '../http/http';

export class User {
    'username': string;
    'firstName': string;
    'lastName': string;
    'phoneNumber'?: string;
    'globalRoles': Array<string>;
    'queueRoles'?: Array<UserRoleAssociation>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "username",
            "baseName": "username",
            "type": "string",
            "format": ""
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string",
            "format": ""
        },
        {
            "name": "phoneNumber",
            "baseName": "phoneNumber",
            "type": "string",
            "format": ""
        },
        {
            "name": "globalRoles",
            "baseName": "globalRoles",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "queueRoles",
            "baseName": "queueRoles",
            "type": "Array<UserRoleAssociation>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return User.attributeTypeMap;
    }

    public constructor() {
    }
}


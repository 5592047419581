import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import * as models from '../models/all';
import { Configuration} from '../configuration'

import { Event } from '../models/Event';
import { EventResponse } from '../models/EventResponse';
import { KKData } from '../models/KKData';
import { Priority } from '../models/Priority';
import { Queue } from '../models/Queue';
import { QueueItem } from '../models/QueueItem';
import { User } from '../models/User';
import { UserRoleAssociation } from '../models/UserRoleAssociation';
import { ObservableDefaultApi } from './ObservableAPI';

import { DefaultApiRequestFactory, DefaultApiResponseProcessor} from "../apis/DefaultApi";
export class PromiseDefaultApi {
    private api: ObservableDefaultApi

    public constructor(
        configuration: Configuration,
        requestFactory?: DefaultApiRequestFactory,
        responseProcessor?: DefaultApiResponseProcessor
    ) {
        this.api = new ObservableDefaultApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * @param queueId 
     * @param queueItem 
     */
    public createItem(queueId: string, queueItem: QueueItem, _options?: Configuration): Promise<QueueItem> {
        const result = this.api.createItem(queueId, queueItem, _options);
        return result.toPromise();
    }

    /**
     * @param queue 
     */
    public createQueue(queue: Queue, _options?: Configuration): Promise<Queue> {
        const result = this.api.createQueue(queue, _options);
        return result.toPromise();
    }

    /**
     * @param queueId 
     * @param itemId 
     */
    public deleteItem(queueId: string, itemId: string, _options?: Configuration): Promise<void> {
        const result = this.api.deleteItem(queueId, itemId, _options);
        return result.toPromise();
    }

    /**
     * @param queueId 
     */
    public deleteQueue(queueId: string, _options?: Configuration): Promise<void> {
        const result = this.api.deleteQueue(queueId, _options);
        return result.toPromise();
    }

    /**
     */
    public getAllQueues(_options?: Configuration): Promise<Array<Queue>> {
        const result = this.api.getAllQueues(_options);
        return result.toPromise();
    }

    /**
     * @param continuationToken 
     */
    public getEvents(continuationToken?: string, _options?: Configuration): Promise<EventResponse> {
        const result = this.api.getEvents(continuationToken, _options);
        return result.toPromise();
    }

    /**
     * @param queueId 
     * @param itemId 
     */
    public getItem(queueId: string, itemId: string, _options?: Configuration): Promise<QueueItem> {
        const result = this.api.getItem(queueId, itemId, _options);
        return result.toPromise();
    }

    /**
     * @param queueId 
     * @param inProgress 
     */
    public getItemsForList(queueId: string, inProgress?: boolean, _options?: Configuration): Promise<Array<QueueItem>> {
        const result = this.api.getItemsForList(queueId, inProgress, _options);
        return result.toPromise();
    }

    /**
     */
    public getMe(_options?: Configuration): Promise<User> {
        const result = this.api.getMe(_options);
        return result.toPromise();
    }

    /**
     * @param queueId 
     */
    public getQueue(queueId: string, _options?: Configuration): Promise<Queue> {
        const result = this.api.getQueue(queueId, _options);
        return result.toPromise();
    }

    /**
     */
    public getUsers(_options?: Configuration): Promise<Array<User>> {
        const result = this.api.getUsers(_options);
        return result.toPromise();
    }

    /**
     * @param queueId 
     * @param itemId 
     * @param queueItem 
     */
    public updateItem(queueId: string, itemId: string, queueItem: QueueItem, _options?: Configuration): Promise<QueueItem> {
        const result = this.api.updateItem(queueId, itemId, queueItem, _options);
        return result.toPromise();
    }

    /**
     * @param queueId 
     * @param queue 
     */
    public updateQueue(queueId: string, queue: Queue, _options?: Configuration): Promise<Queue> {
        const result = this.api.updateQueue(queueId, queue, _options);
        return result.toPromise();
    }

    /**
     * @param queueId 
     * @param itemId 
     * @param lowerRank 
     * @param upperRank 
     */
    public updateRank(queueId: string, itemId: string, lowerRank?: string, upperRank?: string, _options?: Configuration): Promise<QueueItem> {
        const result = this.api.updateRank(queueId, itemId, lowerRank, upperRank, _options);
        return result.toPromise();
    }


}




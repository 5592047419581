import { useQuery } from 'react-query';
import { Queue } from 'ique-api';
import { iqueApi } from './apiConfig';

const getQueueInfo = (id: string): Promise<Queue> => {
  return iqueApi.getQueue(id);
};

type UseQueueInfoOptions = {
  queueId: string;
};

export const useQueueInfo = ({ queueId }: UseQueueInfoOptions) => {
  return useQuery<Queue, Error>({
    queryKey: ['queues/info', queueId],
    queryFn: () => getQueueInfo(queueId),
    refetchInterval: 10000,
    placeholderData: undefined
  });
};

import React from 'react';
import { ActionIcon, Button, Center, Container, Space, Text } from '@mantine/core';
import { QueueItem } from 'ique-api';
import RequireRoleOrIsOwner from '../../../../components/RequireRoleOrIsOwner';
import { ROLES } from '../../../../model/roles';
import { useNotifications } from '@mantine/notifications';
import { useUpdateQueueItem } from '../../../../api/updateQueueItem';
import ItemActionButton from '../ItemActionButton';
import { MdCall } from 'react-icons/md';

type QueueItemDetailsProps = {
  item: QueueItem;
  onSelectQueueItem: (item: QueueItem | undefined) => void;
};

const QueueItemDetails = ({ item, onSelectQueueItem }: QueueItemDetailsProps) => {
  const updateItem = useUpdateQueueItem();
  const notifications = useNotifications();

  const onMarkAsDone = () => {
    updateItem.mutateAsync({ ...item, done: true }).then(() => {
      notifications.showNotification({
        message: 'Beställning klarmarkerad',
        color: 'green'
      });
      onSelectQueueItem(undefined);
    });
  };

  const onConfirm = () => {
    updateItem.mutateAsync({ ...item, confirmed: true }).then(() =>
      notifications.showNotification({
        message: 'Beställning bekräftad',
        color: 'green'
      })
    );
  };

  const handlePauseToggle = () => {
    updateItem.mutateAsync({ ...item, paused: !item.paused }).then(() => {
      return notifications.showNotification({
        message: `Förfrågan ${item.paused ? 'återuppdagen' : 'pausad'}`,
        color: 'green'
      });
    });
  };

  return (
    <Container padding={0}>
      {item.callBefore && (
        <Center>
          <ActionIcon<'a'>
            component="a"
            color="red"
            radius="xs"
            variant="filled"
            mr="md"
            href={`tel:${item.phoneNumber}`}
          >
            <MdCall size={20} />
          </ActionIcon>
          <Text weight="bold" component="span">
            Kontakta anmälare på{' '}
            <Text variant="link" component="a" href={`tel:${item.phoneNumber}`}>
              {item.phoneNumber}
            </Text>
          </Text>
        </Center>
      )}
      <Space h="md" />

      <RequireRoleOrIsOwner owner={item.createdBy}>
        <Button variant="outline" fullWidth onClick={handlePauseToggle}>
          {item.paused ? 'Återuppta förfrågan' : 'Pausa förfrågan'}
        </Button>
      </RequireRoleOrIsOwner>

      <Space h="xl" />

      {item.confirmed ? (
        <ItemActionButton
          item={item}
          roles={[ROLES.ADMIN, ROLES.QUEUE_ADMIN]}
          owner={item.createdBy}
          fullWidth
          onClick={onMarkAsDone}
        >
          Markera som klar
        </ItemActionButton>
      ) : (
        <ItemActionButton item={item} roles={[ROLES.ADMIN, ROLES.QUEUE_ADMIN]} fullWidth onClick={onConfirm}>
          Bekräfta
        </ItemActionButton>
      )}
    </Container>
  );
};

export default QueueItemDetails;
